import React, { useEffect, useState } from "react";
import { Container, Form, Button } from "react-bootstrap";
import { useAuth } from "../context/AuthContext";
import firebaseService from "../FirebaseService";

function Account() {
  const { currentUser } = useAuth();
  const [userData, setUserData] = useState({
    email: "",
    displayName: "",
    phoneNumber: "",
    mailingAddress: "",
    shippingAddress: "",
    optOutFilterEmails: false,
  });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchUserData() {
      if (currentUser) {
        try {
          const userDoc = await firebaseService.getUserById(currentUser.uid);
          if (userDoc) {
            setUserData(userDoc);
          }
          setIsLoading(false);
        } catch (error) {
          console.error("Error fetching user data:", error);
          setIsLoading(false);
        }
      }
    }
    fetchUserData();
  }, [currentUser]);

  function handleChange(event) {
    const { name, value, type, checked } = event.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  }

  async function handleSubmit(event) {
    event.preventDefault();
    try {
      await firebaseService.updateUser(currentUser.uid, {
        displayName: userData.displayName,
        phoneNumber: userData.phoneNumber,
        mailingAddress: userData.mailingAddress,
        shippingAddress: userData.shippingAddress,
      });

      alert("Profile updated successfully.");
    } catch (error) {
      console.error("Error updating profile:", error);
      alert("Error updating profile.");
    }
  }

  if (!currentUser) {
    return (
      <Container className="mt-5">
        <h2>Account Information</h2>
        <p>You are not logged in.</p>
      </Container>
    );
  }

  if (isLoading) {
    return (
      <Container className="mt-5">
        <h2>Account Information</h2>
        <p>Loading...</p>
      </Container>
    );
  }

  return (
    <Container className="mt-5">
      <h2>Account Information</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formEmail" className="mb-3">
          <Form.Label>Email address</Form.Label>
          <Form.Control type="email" value={userData.email} disabled />
        </Form.Group>

        <Form.Group controlId="formDisplayName" className="mb-3">
          <Form.Label>Name (First and Last)</Form.Label>
          <Form.Control
            type="text"
            name="displayName"
            value={userData.displayName || ""}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group controlId="formPhoneNumber" className="mb-3">
          <Form.Label>Phone Number</Form.Label>
          <Form.Control
            type="text"
            name="phoneNumber"
            value={userData.phoneNumber || ""}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group controlId="formMailingAddress" className="mb-3">
          <Form.Label>Mailing Address</Form.Label>
          <Form.Control
            type="text"
            name="mailingAddress"
            value={userData.mailingAddress || ""}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group controlId="formShippingAddress" className="mb-3">
          <Form.Label>Shipping Address</Form.Label>
          <Form.Control
            type="text"
            name="shippingAddress"
            value={userData.shippingAddress || ""}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group controlId="optOutFilterEmails" className="mb-3">
          <Form.Label>Email Preferences:</Form.Label>
          {/* <div className="custom-checkbox-container"> */}
          <Form.Check
            type="checkbox"
            label="Opt out of filter life notification emails"
            name="optOutFilterEmails"
            checked={userData.optOutFilterEmails || false}
            onChange={handleChange}
          />
          {/* </div> */}
          <Form.Text className="text-muted">
            When checked, you will not receive filter life alert emails for
            equipment in your company.
          </Form.Text>
        </Form.Group>

        <Button variant="primary" type="submit">
          Update Profile
        </Button>
      </Form>
    </Container>
  );
}

export default Account;
