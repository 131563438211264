import React, { useEffect, useState, useContext } from "react";
import {
  Card,
  Container,
  Table,
  Button,
  Modal,
  Form,
  InputGroup,
  FormControl,
  ListGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import firebaseService from "../FirebaseService";
import { useAuth } from "../context/AuthContext";
import { NotificationContext } from "../context/NotificationContext";
import useWindowWidth from "../hooks/useWindowWidth";

import "../styles/ModalStyles.css";
import "../styles/TableStyles.css";
import "../styles/CardStyles.css";

function Companies() {
  // Use context functions
  const { showModalError } = useContext(NotificationContext);

  const navigate = useNavigate();
  const width = useWindowWidth();
  const isMobile = width <= 576; // Adjust breakpoint as needed

  const { currentUser } = useAuth();
  const [companies, setCompanies] = useState([]);
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [users, setUsers] = useState([]); // Fetch users for Sales Rep selection
  const [showAddCompanyModal, setShowAddCompanyModal] = useState(false);
  const [showEditCompanyModal, setShowEditCompanyModal] = useState(false);
  const [showUsersModal, setShowUsersModal] = useState(false);
  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [newCompanyData, setNewCompanyData] = useState({
    name: "",
    mailingAddress: "",
    shippingAddress: "",
    phoneNumbers: [""],
    salesRepId: "", // Add salesRepId to company data
  });
  const [currentCompany, setCurrentCompany] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedCompanyUsers, setSelectedCompanyUsers] = useState([]);
  const [currentUserToEdit, setCurrentUserToEdit] = useState(null);
  const [userData, setUserData] = useState({});
  const [showDeleteCompanyModal, setShowDeleteCompanyModal] = useState(false);
  const [companyToDelete, setCompanyToDelete] = useState(null);
  const [userSearchTerm, setUserSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [salesRepSearchTerm, setSalesRepSearchTerm] = useState("");
  const [salesRepSearchResults, setSalesRepSearchResults] = useState([]);
  const [averageMPGPerCompany, setAverageMPGPerCompany] = useState({});
  const [mpgChangePerCompany, setMPGChangePerCompany] = useState({});

  const isAdmin = currentUser?.role === "admin";

  useEffect(() => {
    async function computeAverageMPG(companiesData, equipmentData) {
      // Map equipment by equipmentId
      const equipmentMap = {};
      equipmentData.forEach((equipment) => {
        equipmentMap[equipment.id] = equipment;
      });

      // Map companyId to list of equipment
      const companyEquipmentMap = {};
      equipmentData.forEach((equipment) => {
        const companyId = equipment.companyId;
        if (companyId) {
          if (!companyEquipmentMap[companyId]) {
            companyEquipmentMap[companyId] = [];
          }
          companyEquipmentMap[companyId].push(equipment);
        }
      });

      // Get all equipment IDs
      const equipmentIds = equipmentData.map((e) => e.id);

      // Fetch fuel logs for specific periods
      const now = new Date();
      const endDatePeriod1 = now;
      const startDatePeriod1 = new Date();
      startDatePeriod1.setMonth(startDatePeriod1.getMonth() - 3);

      const endDatePeriod2 = new Date(startDatePeriod1);
      const startDatePeriod2 = new Date();
      startDatePeriod2.setMonth(startDatePeriod2.getMonth() - 6);

      // Fetch fuel logs for period 1 (last 3 months)
      const fuelLogsPeriod1 =
        await firebaseService.getFuelLogsByEquipmentIdsOverRange(
          equipmentIds,
          startDatePeriod1,
          endDatePeriod1
        );

      // Fetch fuel logs for period 2 (previous 3 months)
      const fuelLogsPeriod2 =
        await firebaseService.getFuelLogsByEquipmentIdsOverRange(
          equipmentIds,
          startDatePeriod2,
          endDatePeriod2
        );

      // Compute MPG for each equipment in both periods
      const equipmentMPGMapPeriod1 = computeMPGPerEquipment(
        fuelLogsPeriod1,
        equipmentData
      );

      const equipmentMPGMapPeriod2 = computeMPGPerEquipment(
        fuelLogsPeriod2,
        equipmentData
      );

      // Compute average MPG per company for both periods
      const avgMPGPerCompanyPeriod1 = computeAverageMPGPerCompany(
        companiesData,
        companyEquipmentMap,
        equipmentMPGMapPeriod1
      );

      const avgMPGPerCompanyPeriod2 = computeAverageMPGPerCompany(
        companiesData,
        companyEquipmentMap,
        equipmentMPGMapPeriod2
      );

      // Compute the MPG change per company
      const avgMPGPerCompany = {};
      const mpgChangePerCompany = {};
      companiesData.forEach((company) => {
        const avgMPG1 = avgMPGPerCompanyPeriod1[company.id];
        const avgMPG2 = avgMPGPerCompanyPeriod2[company.id];
        avgMPGPerCompany[company.id] = avgMPG1;

        if (avgMPG1 !== null && avgMPG2 !== null) {
          const change = avgMPG1 - avgMPG2;
          mpgChangePerCompany[company.id] = change;
        } else {
          mpgChangePerCompany[company.id] = null;
        }
      });

      // Update state
      setAverageMPGPerCompany(avgMPGPerCompany);
      setMPGChangePerCompany(mpgChangePerCompany);
    }

    function computeMPGPerEquipment(fuelLogs, equipmentData) {
      // Group fuel logs by equipmentId
      const fuelLogsByEquipmentId = {};
      fuelLogs.forEach((log) => {
        const equipmentId = log.equipmentId;
        if (!fuelLogsByEquipmentId[equipmentId]) {
          fuelLogsByEquipmentId[equipmentId] = [];
        }
        fuelLogsByEquipmentId[equipmentId].push(log);
      });

      // Compute MPG for each equipment
      const equipmentMPGMap = {};

      equipmentData.forEach((equipment) => {
        const logs = fuelLogsByEquipmentId[equipment.id] || [];
        if (logs.length > 0) {
          const totalGallons = logs.reduce(
            (sum, log) => sum + (log.gallons || 0),
            0
          );

          const mileages = logs
            .map((log) => log.currentMileage)
            .filter((m) => m !== null && m !== undefined);

          if (mileages.length > 0 && totalGallons > 0) {
            const minMileage = Math.min(...mileages);
            const maxMileage = Math.max(...mileages);
            const totalDistance = maxMileage - minMileage;

            const mpg = totalDistance / totalGallons;
            equipmentMPGMap[equipment.id] = mpg;
          }
        }
      });

      return equipmentMPGMap;
    }

    function computeAverageMPGPerCompany(
      companiesData,
      companyEquipmentMap,
      equipmentMPGMap
    ) {
      const avgMPGPerCompany = {};
      companiesData.forEach((company) => {
        const equipmentList = companyEquipmentMap[company.id] || [];
        const mpgValues = equipmentList
          .map((e) => equipmentMPGMap[e.id])
          .filter((mpg) => mpg !== undefined && !isNaN(mpg));
        if (mpgValues.length > 0) {
          const avgMPG =
            mpgValues.reduce((sum, mpg) => sum + mpg, 0) / mpgValues.length;
          avgMPGPerCompany[company.id] = avgMPG;
        } else {
          avgMPGPerCompany[company.id] = null;
        }
      });
      return avgMPGPerCompany;
    }

    async function fetchData() {
      try {
        // Fetch companies and users
        const [companiesData, usersData] = await Promise.all([
          firebaseService.getCompanies(currentUser),
          isAdmin
            ? firebaseService.getAllUsers()
            : firebaseService.getUsersByCompanyId(currentUser.companyId),
        ]);
        // Sort companies alphabetically by name before setting the state
        const sortedCompanies = [...companiesData].sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        setCompanies(sortedCompanies);
        setFilteredCompanies(sortedCompanies);
        setUsers(usersData);

        // Fetch all equipment
        let equipmentData;
        if (isAdmin) {
          equipmentData = await firebaseService.getAllEquipment();
        } else {
          equipmentData = await firebaseService.getEquipment(currentUser);
        }

        // Compute average MPG per company
        await computeAverageMPG(companiesData, equipmentData);
      } catch (error) {
        showModalError(error.message);
      }
    }
    if (currentUser) {
      fetchData();
    }
  }, [currentUser, isAdmin, showModalError]);

  useEffect(() => {
    const filtered = companies.filter((company) =>
      company.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredCompanies(filtered);
  }, [searchTerm, companies]);

  function handleAddCompany() {
    setShowAddCompanyModal(true);
    setNewCompanyData({
      name: "",
      mailingAddress: "",
      shippingAddress: "",
      phoneNumbers: [""],
      salesRepId: "",
    });
    setSalesRepSearchTerm("");
    setSalesRepSearchResults([]);
  }

  function handleAddCompanyClose() {
    setShowAddCompanyModal(false);
    setNewCompanyData({
      name: "",
      mailingAddress: "",
      shippingAddress: "",
      phoneNumbers: [""],
      salesRepId: "",
    });
    setSalesRepSearchTerm("");
    setSalesRepSearchResults([]);
  }

  async function handleAddCompanySubmit(event) {
    event.preventDefault();

    // Check if a company with the same name already exists
    const duplicate = companies.some(
      (company) =>
        company.name.toLowerCase() === newCompanyData.name.toLowerCase()
    );
    if (duplicate) {
      showModalError(
        "A company with the same name already exists. Please choose a different name."
      );
      return;
    }

    try {
      await firebaseService.addCompany(newCompanyData);
      handleAddCompanyClose();
      const companiesData = await firebaseService.getCompanies(currentUser);
      setCompanies(companiesData);
    } catch (error) {
      showModalError(error.message);
    }
  }

  function handleNewCompanyChange(event) {
    const { name, value } = event.target;
    setNewCompanyData((prevData) => ({
      ...prevData,
      [name]: name === "phoneNumbers" ? [value] : value,
    }));
  }

  function handleEditCompany(company) {
    setCurrentCompany(company);
    const { createdOn, ...companyWithoutCreatedOn } = company;
    setNewCompanyData({
      ...companyWithoutCreatedOn,
      salesRepId: company.salesRepId || "",
    });
    setShowEditCompanyModal(true);
    setSalesRepSearchTerm("");
    setSalesRepSearchResults([]);
  }

  function handleEditCompanyClose() {
    setShowEditCompanyModal(false);
    setCurrentCompany(null);
    setSalesRepSearchTerm("");
    setSalesRepSearchResults([]);
  }

  async function handleEditCompanySubmit(event) {
    event.preventDefault();

    // Check if a company with the same name already exists, excluding the current company being edited
    const duplicate = companies.some(
      (company) =>
        company.name.toLowerCase() === newCompanyData.name.toLowerCase() &&
        company.id !== currentCompany.id
    );
    if (duplicate) {
      showModalError(
        "A company with the same name already exists. Please choose a different name."
      );
      return;
    }

    try {
      if (currentCompany) {
        // Exclude the createdOn field from the data being updated
        const { createdOn, ...companyDataToUpdate } = newCompanyData;

        await firebaseService.updateCompany(
          currentCompany.id,
          companyDataToUpdate
        );
        setShowEditCompanyModal(false);
        const companiesData = await firebaseService.getCompanies(currentUser);
        setCompanies(companiesData);
      }
    } catch (error) {
      showModalError(error.message);
    }
  }

  function handleCompanyChange(event) {
    const { name, value } = event.target;
    setNewCompanyData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }

  // Function to handle viewing users of a company
  async function handleViewUsers(company) {
    setSelectedCompany(company);
    try {
      const usersData = await firebaseService.getUsersByCompanyId(company.id);
      setSelectedCompanyUsers(usersData);
      setShowUsersModal(true);
    } catch (error) {
      showModalError(error.message);
    }
  }

  // Function to handle editing a user
  function handleEditUser(user) {
    setCurrentUserToEdit(user);
    setUserData(user);
    setShowEditUserModal(true);
  }

  function handleEditUserClose() {
    setShowEditUserModal(false);
    setCurrentUserToEdit(null);
  }

  async function handleEditUserSubmit(event) {
    event.preventDefault();
    try {
      if (currentUserToEdit) {
        await firebaseService.updateUser(currentUserToEdit.id, userData);
        setShowEditUserModal(false);
        // Refresh the users list in the modal
        handleViewUsers(selectedCompany);
      }
    } catch (error) {
      showModalError(error.message);
    }
  }

  function handleUserDataChange(event) {
    const { name, value } = event.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }

  function handleDeleteCompany(company) {
    setCompanyToDelete(company);
    setShowDeleteCompanyModal(true);
  }

  function handleDeleteCompanyClose() {
    setShowDeleteCompanyModal(false);
    setCompanyToDelete(null);
  }

  async function handleConfirmDeleteCompany() {
    try {
      if (companyToDelete) {
        await firebaseService.deleteCompany(companyToDelete.id);
        setShowDeleteCompanyModal(false);
        setCompanyToDelete(null);
        // Refresh the companies list
        const companiesData = await firebaseService.getCompanies(currentUser);
        setCompanies(companiesData);
      }
    } catch (error) {
      showModalError(error.message);
    }
  }

  // Function to handle searching users not in the company
  async function handleSearchUsers() {
    try {
      if (userSearchTerm.trim() === "") {
        setSearchResults([]);
        return;
      }
      const usersData = await firebaseService.searchUsersNotInCompany(
        userSearchTerm,
        selectedCompany.id
      );
      setSearchResults(usersData);
    } catch (error) {
      showModalError(error.message);
    }
  }

  // Function to handle adding a user to the company
  async function handleAddUserToCompany(user) {
    try {
      await firebaseService.updateUser(user.id, {
        companyId: selectedCompany.id,
      });
      // Refresh the users list in the modal
      handleViewUsers(selectedCompany);
      // Clear the search results
      setSearchResults([]);
      setUserSearchTerm("");
    } catch (error) {
      showModalError(error.message);
    }
  }

  // Function to handle removing a user from the company
  async function handleRemoveUserFromCompany(user) {
    try {
      await firebaseService.updateUser(user.id, { companyId: "" });
      // Refresh the users list in the modal
      handleViewUsers(selectedCompany);
    } catch (error) {
      showModalError(error.message);
    }
  }

  // Sales Rep Search Functions
  function handleSalesRepSearch() {
    try {
      if (salesRepSearchTerm.trim() === "") {
        setSalesRepSearchResults([]);
        return;
      }
      const results = users.filter(
        (user) =>
          (user.displayName &&
            user.displayName
              .toLowerCase()
              .includes(salesRepSearchTerm.toLowerCase())) ||
          user.email.toLowerCase().includes(salesRepSearchTerm.toLowerCase())
      );
      setSalesRepSearchResults(results);
    } catch (error) {
      showModalError(error.message);
    }
  }

  function handleSelectSalesRep(user) {
    setNewCompanyData((prevData) => ({
      ...prevData,
      salesRepId: user.id,
    }));
  }

  function handleRemoveSalesRep() {
    setNewCompanyData((prevData) => ({
      ...prevData,
      salesRepId: "",
    }));
  }

  return (
    <Container className="mt-5">
      <div className="table-container">
        <h2>Companies</h2>
        {isAdmin && (
          <Button
            variant="primary"
            size="sm"
            onClick={handleAddCompany}
            className="mb-3"
          >
            Add Company
          </Button>
        )}
        {/* Search Box */}
        <InputGroup className="mb-3">
          <FormControl
            placeholder="Search by company name"
            aria-label="Search by company name"
            aria-describedby="basic-addon2"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </InputGroup>
        {isMobile ? (
          <div>
            {filteredCompanies.map((company) => (
              <Card key={company.id} className="mb-3 card-dark">
                <Card.Body>
                  <Card.Title>{company.name}</Card.Title>
                  <Card.Text>
                    <strong>Mailing Address:</strong>{" "}
                    {company.mailingAddress ?? "N/A"}
                    <br />
                    <strong>Shipping Address:</strong>{" "}
                    {company.shippingAddress ?? "N/A"}
                    <br />
                    <strong>Phone Number:</strong>{" "}
                    {company.phoneNumbers?.[0] ?? "N/A"}
                    <br />
                    <strong>Avg. MPG:</strong>{" "}
                    {averageMPGPerCompany[company.id]
                      ? averageMPGPerCompany[company.id].toFixed(2)
                      : "N/A"}
                    {mpgChangePerCompany[company.id] !== null &&
                      mpgChangePerCompany[company.id] !== undefined && (
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip>
                              Change from previous 3 months:{" "}
                              {mpgChangePerCompany[company.id].toFixed(2)}
                            </Tooltip>
                          }
                        >
                          <span
                            style={{
                              color:
                                mpgChangePerCompany[company.id] > 0
                                  ? "green"
                                  : "red",
                              marginLeft: "5px",
                              cursor: "pointer",
                            }}
                          >
                            {mpgChangePerCompany[company.id] > 0 ? "↑" : "↓"}
                          </span>
                        </OverlayTrigger>
                      )}
                    <br />
                    <strong>Sales Rep:</strong>{" "}
                    {(() => {
                      const salesRep = users.find(
                        (user) => user.id === company.salesRepId
                      );
                      return salesRep
                        ? `${salesRep.displayName || salesRep.email} (${
                            salesRep.role
                          })`
                        : "N/A";
                    })()}
                  </Card.Text>
                  <Button
                    variant="info"
                    size="sm"
                    onClick={() => handleViewUsers(company)}
                  >
                    View Users
                  </Button>{" "}
                  {isAdmin && (
                    <>
                      <Button
                        variant="warning"
                        size="sm"
                        onClick={() => handleEditCompany(company)}
                      >
                        Edit
                      </Button>{" "}
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={() => handleDeleteCompany(company)}
                      >
                        Delete
                      </Button>
                    </>
                  )}
                </Card.Body>
              </Card>
            ))}
          </div>
        ) : (
          <div className="table-responsive">
            <Table striped bordered hover variant="dark" responsive>
              <thead>
                <tr>
                  <th>Company Name</th>
                  <th className="d-none d-sm-table-cell">Mailing Address</th>
                  <th className="d-none d-md-table-cell">Shipping Address</th>
                  <th className="d-lg-table-cell">Phone Number</th>
                  <th>Avg. MPG</th>
                  <th>Sales Rep</th>
                  <th>Users</th>
                  {isAdmin && <th>Actions</th>}
                </tr>
              </thead>
              <tbody>
                {filteredCompanies.map((company) => (
                  <tr key={company.id}>
                    <td className="truncate">{company.name}</td>
                    <td className="d-none d-sm-table-cell">
                      {company.mailingAddress ?? "N/A"}
                    </td>
                    <td className="d-none d-md-table-cell">
                      {company.shippingAddress ?? "N/A"}
                    </td>
                    <td className="d-lg-table-cell">
                      {company.phoneNumbers?.[0] ?? "N/A"}
                    </td>
                    <td>
                      {averageMPGPerCompany[company.id]
                        ? averageMPGPerCompany[company.id].toFixed(2)
                        : "N/A"}
                      {mpgChangePerCompany[company.id] !== null &&
                        mpgChangePerCompany[company.id] !== undefined && (
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip>
                                Change from previous 3 months:{" "}
                                {mpgChangePerCompany[company.id].toFixed(2)}
                              </Tooltip>
                            }
                          >
                            <span
                              style={{
                                color:
                                  mpgChangePerCompany[company.id] > 0
                                    ? "green"
                                    : "red",
                                marginLeft: "5px",
                                cursor: "pointer",
                              }}
                            >
                              {mpgChangePerCompany[company.id] > 0 ? "↑" : "↓"}
                            </span>
                          </OverlayTrigger>
                        )}
                    </td>
                    <td>
                      {(() => {
                        const salesRep = users.find(
                          (user) => user.id === company.salesRepId
                        );
                        return salesRep
                          ? `${salesRep.displayName || salesRep.email} (${
                              salesRep.role
                            })`
                          : "N/A";
                      })()}
                    </td>
                    <td>
                      <Button
                        variant="info"
                        size="sm"
                        onClick={() => handleViewUsers(company)}
                      >
                        View Users
                      </Button>
                    </td>
                    {isAdmin && (
                      <td>
                        <Button
                          variant="warning"
                          size="sm"
                          onClick={() => handleEditCompany(company)}
                        >
                          Edit Company Profile
                        </Button>{" "}
                        <Button
                          variant="danger"
                          size="sm"
                          onClick={() => handleDeleteCompany(company)}
                        >
                          Delete
                        </Button>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
      </div>
      {/* Add Company Modal */}
      <Modal show={showAddCompanyModal} onHide={handleAddCompanyClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Company</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleAddCompanySubmit}>
          <Modal.Body>
            <Form.Group controlId="formCompanyName" className="mb-3">
              <Form.Label>Company Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={newCompanyData.name}
                onChange={handleNewCompanyChange}
                required
                placeholder="Enter company name"
              />
            </Form.Group>
            <Form.Group controlId="formMailingAddress" className="mb-3">
              <Form.Label>Mailing Address</Form.Label>
              <Form.Control
                type="text"
                name="mailingAddress"
                value={newCompanyData.mailingAddress}
                onChange={handleNewCompanyChange}
                required
                placeholder="Enter mailing address"
              />
            </Form.Group>
            <Form.Group controlId="formShippingAddress" className="mb-3">
              <Form.Label>Shipping Address</Form.Label>
              <Form.Control
                type="text"
                name="shippingAddress"
                value={newCompanyData.shippingAddress}
                onChange={handleNewCompanyChange}
                required
                placeholder="Enter shipping address"
              />
            </Form.Group>
            <Form.Group controlId="formPhoneNumber" className="mb-3">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="text"
                name="phoneNumbers"
                value={newCompanyData.phoneNumbers?.[0]}
                onChange={(e) =>
                  setNewCompanyData((prevData) => ({
                    ...prevData,
                    phoneNumbers: [e.target.value],
                  }))
                }
                required
                placeholder="Enter phone number"
              />
            </Form.Group>
            {/* Sales Rep Selection */}
            <Form.Group controlId="salesRepSearch" className="mb-3">
              <Form.Label>Select Sales Rep</Form.Label>
              <InputGroup className="mb-3">
                <FormControl
                  placeholder="Search users by name or email"
                  aria-label="Search users"
                  value={salesRepSearchTerm}
                  onChange={(e) => setSalesRepSearchTerm(e.target.value)}
                />
                <Button variant="primary" onClick={handleSalesRepSearch}>
                  Search
                </Button>
              </InputGroup>
              {salesRepSearchResults.length > 0 && (
                <ListGroup variant="flush">
                  {salesRepSearchResults.map((user) => (
                    <ListGroup.Item key={user.id}>
                      <div className="d-flex justify-content-between align-items-center">
                        <div>
                          {user.displayName || user.email} ({user.role})
                        </div>
                        <Button
                          variant="success"
                          size="sm"
                          onClick={() => handleSelectSalesRep(user)}
                        >
                          Select
                        </Button>
                      </div>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
              {newCompanyData.salesRepId && (
                <div className="mt-2">
                  <strong>Selected Sales Rep:</strong>{" "}
                  {(() => {
                    const selectedUser = users.find(
                      (user) => user.id === newCompanyData.salesRepId
                    );
                    return selectedUser
                      ? `${selectedUser.displayName || selectedUser.email} (${
                          selectedUser.role
                        })`
                      : "Unknown";
                  })()}
                  <Button
                    variant="danger"
                    size="sm"
                    className="ms-2"
                    onClick={handleRemoveSalesRep}
                  >
                    Remove
                  </Button>
                </div>
              )}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleAddCompanyClose}>
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              Add Company
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      {/* Edit Company Modal */}
      <Modal show={showEditCompanyModal} onHide={handleEditCompanyClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Company</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleEditCompanySubmit}>
          <Modal.Body>
            <Form.Group controlId="editCompanyName" className="mb-3">
              <Form.Label>Company Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={newCompanyData.name}
                onChange={handleCompanyChange}
                required
                placeholder="Enter company name"
              />
            </Form.Group>
            <Form.Group controlId="editMailingAddress" className="mb-3">
              <Form.Label>Mailing Address</Form.Label>
              <Form.Control
                type="text"
                name="mailingAddress"
                value={newCompanyData.mailingAddress}
                onChange={handleCompanyChange}
                required
                placeholder="Enter mailing address"
              />
            </Form.Group>
            <Form.Group controlId="editShippingAddress" className="mb-3">
              <Form.Label>Shipping Address</Form.Label>
              <Form.Control
                type="text"
                name="shippingAddress"
                value={newCompanyData.shippingAddress}
                onChange={handleCompanyChange}
                required
                placeholder="Enter shipping address"
              />
            </Form.Group>
            <Form.Group controlId="editPhoneNumber" className="mb-3">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="text"
                name="phoneNumbers"
                value={newCompanyData.phoneNumbers?.[0]}
                onChange={(e) =>
                  setNewCompanyData((prevData) => ({
                    ...prevData,
                    phoneNumbers: [e.target.value],
                  }))
                }
                required
                placeholder="Enter phone number"
              />
            </Form.Group>
            {/* Sales Rep Selection */}
            <Form.Group controlId="editSalesRepSearch" className="mb-3">
              <Form.Label>Select Sales Rep</Form.Label>
              <InputGroup className="mb-3">
                <FormControl
                  placeholder="Search users by name or email"
                  aria-label="Search users"
                  value={salesRepSearchTerm}
                  onChange={(e) => setSalesRepSearchTerm(e.target.value)}
                />
                <Button variant="primary" onClick={handleSalesRepSearch}>
                  Search
                </Button>
              </InputGroup>
              {salesRepSearchResults.length > 0 && (
                <ListGroup variant="flush">
                  {salesRepSearchResults.map((user) => (
                    <ListGroup.Item key={user.id}>
                      <div className="d-flex justify-content-between align-items-center">
                        <div>
                          {user.displayName || user.email} ({user.role})
                        </div>
                        <Button
                          variant="success"
                          size="sm"
                          onClick={() => handleSelectSalesRep(user)}
                        >
                          Select
                        </Button>
                      </div>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
              {newCompanyData.salesRepId && (
                <div className="mt-2">
                  <strong>Selected Sales Rep:</strong>{" "}
                  {(() => {
                    const selectedUser = users.find(
                      (user) => user.id === newCompanyData.salesRepId
                    );
                    return selectedUser
                      ? `${selectedUser.displayName || selectedUser.email} (${
                          selectedUser.role
                        })`
                      : "Unknown";
                  })()}
                  <Button
                    variant="danger"
                    size="sm"
                    className="ms-2"
                    onClick={handleRemoveSalesRep}
                  >
                    Remove
                  </Button>
                </div>
              )}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleEditCompanyClose}>
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              Save Changes
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      {/* Delete Company Confirmation Modal */}
      <Modal show={showDeleteCompanyModal} onHide={handleDeleteCompanyClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete the company{" "}
          <strong>{companyToDelete?.name}</strong>? This action cannot be
          undone.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleDeleteCompanyClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleConfirmDeleteCompany}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Users Modal */}
      <Modal
        show={showUsersModal}
        onHide={() => setShowUsersModal(false)}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Users for {selectedCompany?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "70vh", overflowY: "auto" }}>
          {selectedCompanyUsers.length > 0 ? (
            <ListGroup variant="flush">
              {selectedCompanyUsers.map((user) => (
                <ListGroup.Item key={user.id}>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <strong>{user.displayName || user.email}</strong> -{" "}
                      {user.role}
                    </div>
                    <div>
                      <Button
                        variant="primary"
                        onClick={() => handleEditUser(user)}
                        size="sm"
                      >
                        Edit
                      </Button>
                      <>&nbsp;</>
                      <Button
                        variant="secondary"
                        size="sm"
                        onClick={() => navigate("/users")}
                      >
                        Go to Users Page
                      </Button>
                      <>&nbsp;</>
                      {isAdmin && (
                        <Button
                          variant="danger"
                          size="sm"
                          onClick={() => handleRemoveUserFromCompany(user)}
                        >
                          Remove
                        </Button>
                      )}
                    </div>
                  </div>
                </ListGroup.Item>
              ))}
            </ListGroup>
          ) : (
            <p>No users associated with this company.</p>
          )}
          {isAdmin && (
            <>
              <hr />
              <h5>Add User to Company</h5>
              <InputGroup className="mb-3">
                <FormControl
                  placeholder="Search users by email or name"
                  aria-label="Search users"
                  value={userSearchTerm}
                  onChange={(e) => setUserSearchTerm(e.target.value)}
                />
                <Button variant="primary" onClick={handleSearchUsers}>
                  Search
                </Button>
              </InputGroup>
              {searchResults.length > 0 ? (
                <ListGroup variant="flush">
                  {searchResults.map((user) => (
                    <ListGroup.Item key={user.id}>
                      <div className="d-flex justify-content-between align-items-center">
                        <div>
                          <strong>{user.displayName || user.email}</strong> -{" "}
                          {user.role}
                        </div>
                        <div>
                          <Button
                            variant="success"
                            size="sm"
                            onClick={() => handleAddUserToCompany(user)}
                          >
                            Add
                          </Button>
                        </div>
                      </div>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              ) : (
                <p>No users found.</p>
              )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowUsersModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Edit User Modal */}
      <Modal show={showEditUserModal} onHide={handleEditUserClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit User</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleEditUserSubmit}>
          <Modal.Body>
            <Form.Group controlId="formDisplayName">
              <Form.Label>Display Name</Form.Label>
              <Form.Control
                type="text"
                name="displayName"
                value={userData.displayName || ""}
                onChange={handleUserDataChange}
                placeholder="Enter display name"
              />
            </Form.Group>
            <Form.Group controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={userData.email || ""}
                onChange={handleUserDataChange}
                placeholder="Enter email"
                disabled
              />
            </Form.Group>
            <Form.Group controlId="formPhoneNumber">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="text"
                name="phoneNumber"
                value={userData.phoneNumber || ""}
                onChange={handleUserDataChange}
                placeholder="Enter phone number"
              />
            </Form.Group>
            <Form.Group controlId="formMailingAddress">
              <Form.Label>Mailing Address</Form.Label>
              <Form.Control
                type="text"
                name="mailingAddress"
                value={userData.mailingAddress || ""}
                onChange={handleUserDataChange}
                placeholder="Enter mailing address"
              />
            </Form.Group>
            <Form.Group controlId="formShippingAddress">
              <Form.Label>Shipping Address</Form.Label>
              <Form.Control
                type="text"
                name="shippingAddress"
                value={userData.shippingAddress || ""}
                onChange={handleUserDataChange}
                placeholder="Enter shipping address"
              />
            </Form.Group>
            <Form.Group controlId="formRole">
              <Form.Label>Role</Form.Label>
              <Form.Control
                as="select"
                name="role"
                value={userData.role || "operator"}
                onChange={handleUserDataChange}
              >
                <option value="admin">Admin</option>
                <option value="owner">Owner</option>
                <option value="operator">Operator</option>
                <option value="mechanic">Mechanic</option>
                <option value="billing">Billing</option>
                <option value="pending">Pending</option>
                <option value="denied">Denied</option>
              </Form.Control>
            </Form.Group>
            {/* If you want to allow changing the company, include this field */}
            <Form.Group controlId="formCompanyId">
              <Form.Label>Company</Form.Label>
              <Form.Control
                as="select"
                name="companyId"
                value={userData.companyId || ""}
                onChange={handleUserDataChange}
                disabled={!isAdmin}
              >
                <option value="">Select Company</option>
                {companies.map((company) => (
                  <option key={company.id} value={company.id}>
                    {company.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleEditUserClose}>
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              Save Changes
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </Container>
  );
}

export default Companies;
