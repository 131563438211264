import React, { useEffect, useState, useContext } from "react";
import {
  Container,
  Table,
  Form,
  InputGroup,
  FormControl,
  Pagination,
  OverlayTrigger,
  Tooltip,
  Button,
} from "react-bootstrap";
import { useAuth } from "../context/AuthContext";
import { NotificationContext } from "../context/NotificationContext";
import firebaseService from "../FirebaseService";
import { formatNumberWithCommas } from "../utils/numberUtils";
import {
  fromFirestoreTimestamp,
  formatDateForDisplay,
} from "../utils/dateUtils";
import { toNumberSafe } from "../utils/numberUtils";
import * as XLSX from "xlsx";
import "./EquipmentAllStyles.css";

// Helper function to safely get timestamp millis
function safeGetMillis(timestamp) {
  return timestamp && typeof timestamp.toMillis === "function"
    ? timestamp.toMillis()
    : 0;
}

// Helper function to safely get a date from a timestamp
function safeGetDate(timestamp) {
  return timestamp && typeof timestamp.toDate === "function"
    ? timestamp.toDate()
    : new Date();
}

// Helper function for safe date comparison
// function safeDateCompare(a, b) {
//   const aTimestamp = a && a.filterChangeDate;
//   const bTimestamp = b && b.filterChangeDate;

//   if (!aTimestamp) return 1; // null dates sort to the end
//   if (!bTimestamp) return -1;

//   return safeGetMillis(bTimestamp) - safeGetMillis(aTimestamp);
// }

// Helper function for safe log date comparison
function safeLogDateCompare(a, b) {
  const aTimestamp = a && a.logDate;
  const bTimestamp = b && b.logDate;

  if (!aTimestamp) return 1; // null dates sort to the end
  if (!bTimestamp) return -1;

  return safeGetMillis(bTimestamp) - safeGetMillis(aTimestamp);
}

function EquipmentAll() {
  const { currentUser } = useAuth();
  const { showModalError } = useContext(NotificationContext);

  const isAdmin = currentUser?.role === "admin";

  const [companies, setCompanies] = useState([]);
  const [equipmentList, setEquipmentList] = useState([]);
  const [filteredEquipment, setFilteredEquipment] = useState([]);
  const [selectedCompanyFilter, setSelectedCompanyFilter] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;

  const [fuelLogsMap, setFuelLogsMap] = useState({});
  const [serviceLogsMap, setServiceLogsMap] = useState({});
  const [companiesMap, setCompaniesMap] = useState({});
  const [filterTypeMap, setFilterTypeMap] = useState({});

  // New state for equipment metrics
  const [metricsMap, setMetricsMap] = useState({});

  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    async function fetchData() {
      try {
        let companiesData = [];
        if (isAdmin) {
          companiesData = await firebaseService.getAllCompanies();
        } else {
          // Non-admin: get their single company
          const company = await firebaseService.getCompanyById(
            currentUser.companyId
          );
          if (company) companiesData = [company];
        }

        const equipmentData = isAdmin
          ? await firebaseService.getAllEquipment()
          : await firebaseService.getEquipmentByCompanyId(
              currentUser.companyId
            );

        const filterTypesData = await firebaseService.getFilterTypes();

        // Create maps for easy lookup
        const cMap = {};
        companiesData.forEach((c) => (cMap[c.id] = c));

        const ftMap = {};
        filterTypesData.forEach((ft) => (ftMap[ft.id] = ft));

        // Sort equipment by company name, then by truck number
        equipmentData.sort((a, b) => {
          const companyA =
            a.companyId && cMap[a.companyId] ? cMap[a.companyId].name : "";
          const companyB =
            b.companyId && cMap[b.companyId] ? cMap[b.companyId].name : "";

          // Compare company names first
          if (companyA < companyB) return -1;
          if (companyA > companyB) return 1;

          // If same company, compare truck numbers (treat as strings to handle non-numeric parts)
          const truckA = a.truckNumber ? a.truckNumber.toLowerCase() : "";
          const truckB = b.truckNumber ? b.truckNumber.toLowerCase() : "";
          if (truckA < truckB) return -1;
          if (truckA > truckB) return 1;
          return 0;
        });

        setCompaniesMap(cMap);
        setFilterTypeMap(ftMap);
        // Sort companies alphabetically by name before setting the state
        const sortedCompanies = [...companiesData].sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        setCompanies(sortedCompanies);
        setEquipmentList(equipmentData);

        // Fetch equipment metrics for all equipments
        const eqIds = equipmentData.map((eq) => eq.id);
        const allMetrics =
          await firebaseService.getEquipmentMetricsByEquipmentIds(eqIds);
        const mMap = {};
        allMetrics.forEach((m) => {
          mMap[m.equipmentId] = m;
        });
        setMetricsMap(mMap);
      } catch (error) {
        showModalError(error.message);
      }
    }
    fetchData();
  }, [isAdmin, currentUser, showModalError]);

  useEffect(() => {
    let filtered = equipmentList;

    // Filter by company if selected
    if (selectedCompanyFilter) {
      filtered = filtered.filter(
        (eq) => eq.companyId === selectedCompanyFilter
      );
    }

    // Filter by search term (truckNumber or filterSerialNumber)
    if (searchTerm) {
      filtered = filtered.filter(
        (eq) =>
          (eq.truckNumber &&
            eq.truckNumber.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (eq.filterSerialNumber &&
            eq.filterSerialNumber
              .toLowerCase()
              .includes(searchTerm.toLowerCase()))
      );
    }

    setFilteredEquipment(filtered);

    // Reset the current page to 1
    setCurrentPage(1);
  }, [equipmentList, selectedCompanyFilter, searchTerm]);

  // Fetch all fuel logs and service logs for filtered equipment (for the current page)
  useEffect(() => {
    async function fetchLogs() {
      if (filteredEquipment.length === 0) {
        setFuelLogsMap({});
        setServiceLogsMap({});
        return;
      }

      const eqIds = filteredEquipment.map((eq) => eq.id);

      const allFuelLogs = await firebaseService.getFuelLogsByEquipmentIds(
        eqIds
      );
      const allServiceLogs = await firebaseService.getServiceLogsByEquipmentIds(
        eqIds
      );

      // Group fuel logs by eq
      const fuelGrouped = {};
      allFuelLogs.forEach((log) => {
        if (!fuelGrouped[log.equipmentId]) fuelGrouped[log.equipmentId] = [];
        fuelGrouped[log.equipmentId].push(log);
      });

      // Group service logs by eq and find the latest one
      const serviceGrouped = {};
      allServiceLogs.forEach((log) => {
        if (!serviceGrouped[log.equipmentId])
          serviceGrouped[log.equipmentId] = [];
        serviceGrouped[log.equipmentId].push(log);
      });

      // Sort service logs desc by date and pick latest
      for (const eqId in serviceGrouped) {
        serviceGrouped[eqId].sort((a, b) => {
          // Handle cases where filterChangeDate might be null
          if (!a.filterChangeDate) return 1; // null dates sort to the end
          if (!b.filterChangeDate) return -1;

          // Normal case - both dates exist
          return b.filterChangeDate.toMillis() - a.filterChangeDate.toMillis();
        });

        serviceGrouped[eqId] = serviceGrouped[eqId][0]; // latest
      }

      setFuelLogsMap(fuelGrouped);
      setServiceLogsMap(serviceGrouped);
    }

    fetchLogs();
  }, [filteredEquipment]);

  // Pagination
  const totalPages = Math.ceil(filteredEquipment.length / itemsPerPage);
  const paginatedEquipment = filteredEquipment.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  function handlePageChange(pageNumber) {
    setCurrentPage(pageNumber);
  }

  function renderPagination() {
    const items = [];
    for (let number = 1; number <= totalPages; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === currentPage}
          onClick={() => handlePageChange(number)}
        >
          {number}
        </Pagination.Item>
      );
    }
    return <Pagination>{items}</Pagination>;
  }

  function getTextColorForRemaining(remaining, max) {
    if (!max || max <= 0 || !remaining) return "";
    const ratio = remaining / max;
    if (ratio < 0.0) {
      return "red";
    } else if (ratio < 0.1) {
      return "orange";
    } else if (ratio < 0.3) {
      return "yellow";
    }
    return "";
  }

  // Export JSON
  const handleExportJSON = () => {
    // Use filteredEquipment instead of paginatedEquipment
    const dataToExport = filteredEquipment.map((eq) => {
      const metrics = metricsMap[eq.id] || {};
      return {
        truckNumber: eq.truckNumber,
        engineMakeModel: eq.engineMakeModel,
        estMPG: eq.estMPG,
        fuelFilterType: eq.fuelFilterTypeId
          ? filterTypeMap[eq.fuelFilterTypeId]?.name
          : "",
        oilFilterType: eq.oilFilterTypeId
          ? filterTypeMap[eq.oilFilterTypeId]?.name
          : "",
        company:
          eq.companyId && companiesMap[eq.companyId]
            ? companiesMap[eq.companyId].name
            : "Unassigned",
        filterSerialNumber: eq.filterSerialNumber,
        initialMileage: eq.initialMileage,
        initialHours: eq.initialHours,
        maxFilterHours: metrics.filterLifeHoursMax,
        remainingFilterHours: metrics.filterLifeHoursRemaining,
        maxFilterMiles: metrics.filterLifeMileageMax,
        remainingFilterMiles: metrics.filterLifeMileageRemaining,
        engineHoursAtInstall: metrics.engineHours,
        mileageAtInstall: metrics.mileage,
      };
    });
    const jsonStr = JSON.stringify(dataToExport, null, 2);
    const blob = new Blob([jsonStr], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "equipment_data.json";
    a.click();
    URL.revokeObjectURL(url);
  };

  // Export CSV
  const handleExportCSV = () => {
    const headers = [
      "Truck Number",
      "Engine Make/Model",
      "Init Est MPG",
      "Filter Type",
      "Company",
      "Filter S/N",
      "Initial Mileage",
      "Initial Hours",
      "Max Filter Hours",
      "Remaining Filter Hours",
      "Max Filter Miles",
      "Remaining Filter Miles",
      "Engine Hours (At Install)",
      "Mileage (At Install)",
    ];
    const rows = filteredEquipment.map((eq) => {
      const metrics = metricsMap[eq.id] || {};
      return [
        eq.truckNumber || "",
        eq.engineMakeModel || "",
        eq.estMPG || "",
        eq.fuelFilterTypeId
          ? filterTypeMap[eq.fuelFilterTypeId]?.name || ""
          : "",
        eq.oilFilterTypeId ? filterTypeMap[eq.oilFilterTypeId]?.name || "" : "",
        eq.companyId && companiesMap[eq.companyId]
          ? companiesMap[eq.companyId].name
          : "Unassigned",
        eq.filterSerialNumber || "",
        eq.initialMileage || "",
        eq.initialHours || "",
        metrics.filterLifeHoursMax || "",
        metrics.filterLifeHoursRemaining || "",
        metrics.filterLifeMileageMax || "",
        metrics.filterLifeMileageRemaining || "",
        metrics.engineHours || "",
        metrics.mileage || "",
      ];
    });

    let csvContent =
      "data:text/csv;charset=utf-8," +
      headers.join(",") +
      "\n" +
      rows.map((e) => e.map((v) => `"${v}"`).join(",")).join("\n");

    const encodedUri = encodeURI(csvContent);
    const a = document.createElement("a");
    a.href = encodedUri;
    a.download = "equipment_data.csv";
    a.click();
  };

  // Export XLSX
  const handleExportXLSX = () => {
    const wsData = [
      [
        "Truck Number",
        "Engine Make/Model",
        "Init Est MPG",
        "Filter Type",
        "Company",
        "Filter S/N",
        "Initial Mileage",
        "Initial Hours",
        "Max Filter Hours",
        "Remaining Filter Hours",
        "Max Filter Miles",
        "Remaining Filter Miles",
        "Engine Hours (At Install)",
        "Mileage (At Install)",
      ],
    ];

    filteredEquipment.forEach((eq) => {
      const metrics = metricsMap[eq.id] || {};
      wsData.push([
        eq.truckNumber || "",
        eq.engineMakeModel || "",
        eq.estMPG || "",
        eq.fuelFilterTypeId
          ? filterTypeMap[eq.fuelFilterTypeId]?.name || ""
          : "",
        eq.oilFilterTypeId ? filterTypeMap[eq.oilFilterTypeId]?.name || "" : "",
        eq.companyId && companiesMap[eq.companyId]
          ? companiesMap[eq.companyId].name
          : "Unassigned",
        eq.filterSerialNumber || "",
        eq.initialMileage || "",
        eq.initialHours || "",
        metrics.filterLifeHoursMax || "",
        metrics.filterLifeHoursRemaining || "",
        metrics.filterLifeMileageMax || "",
        metrics.filterLifeMileageRemaining || "",
        metrics.engineHours || "",
        metrics.mileage || "",
      ]);
    });

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet(wsData);
    XLSX.utils.book_append_sheet(wb, ws, "EquipmentData");
    XLSX.writeFile(wb, "equipment_data.xlsx");
  };

  // This function fetches the single equipment's data, then builds a single worksheet
  const handleExportSingleEquipmentExcel = async (eq) => {
    try {
      const equipmentId = eq.id;

      // 1) Fetch everything we need from the Firebase service:
      const {
        eqDoc, // single "equipment" doc
        filterLogs, // array of service logs
        fuelLogs, // array of fuel logs
        metrics, // single metrics doc
        eqAvgMPG, // array of monthly mpg docs
      } = await firebaseService.getExportDataForEquipment(equipmentId);

      if (!eqDoc) {
        throw new Error("Equipment document not found or does not exist.");
      }

      // 2) Build an array-of-arrays (AOA) for XLSX, with 4 blank rows in between
      const wsData = [];

      // helper function to insert 4 blank rows
      const addBlankRows = () => wsData.push([], [], [], []);

      // -----------------------------------------------------------------------------
      // (A) EQUIPMENT TABLE
      // -----------------------------------------------------------------------------
      {
        // Header row in light blue
        const headers = [
          "id",
          "truckNumber",
          "engineMakeModel",
          "companyId",
          // add any other fields from eqDoc you want
        ];

        wsData.push(["Equipment Collection"]); // Section label
        wsData.push(headers); // Header row
        // Then we add the single row
        wsData.push([
          eqDoc.id,
          eqDoc.truckNumber ?? "",
          eqDoc.engineMakeModel ?? "",
          eqDoc.companyId ?? "",
          // ...
        ]);
      }
      addBlankRows(); // 4 blank rows

      // -----------------------------------------------------------------------------
      // (B) FILTER LOGS (SERVICE LOGS) - sort newest to oldest
      // -----------------------------------------------------------------------------
      {
        // Sort from newest to oldest
        const sortedFilterLogs = [...filterLogs].sort((a, b) => {
          // Handle cases where filterChangeDate might be null
          if (!a.filterChangeDate) return 1; // null dates sort to the end
          if (!b.filterChangeDate) return -1;

          // Normal case - both dates exist
          return b.filterChangeDate.toMillis() - a.filterChangeDate.toMillis();
        });

        // We want all possible columns for filter logs
        // e.g. ID, filterChangeDate, engineHours, currentMileage, createdBy...
        const headers = [
          "id",
          "filterChangeDate",
          "engineHours",
          "currentMileage",
          "createdBy", // if you want
        ];

        wsData.push(["Filter Logs Collection"]);
        wsData.push(headers);

        sortedFilterLogs.forEach((log) => {
          wsData.push([
            log.id,
            log.filterChangeDate
              ? log.filterChangeDate.toDate().toLocaleString()
              : "",
            log.engineHours ?? "",
            log.currentMileage ?? "",
            log.createdBy ?? "",
          ]);
        });
      }
      addBlankRows();

      // -----------------------------------------------------------------------------
      // (C) FUEL LOGS - sort newest to oldest
      // -----------------------------------------------------------------------------
      const sortedFuelLogs = [...fuelLogs].sort((a, b) => {
        if (!a.logDate) return 1;
        if (!b.logDate) return -1;
        return b.logDate.toMillis() - a.logDate.toMillis();
      });

      {
        // Build headers for all fields in your fuel logs
        // e.g. ID, logDate, engineHours, currentMileage, gallons, defGallons, userId, ...
        const headers = [
          "id",
          "logDate",
          "engineHours",
          "currentMileage",
          "gallons",
          "defGallons",
          "userId", // if you want
        ];

        wsData.push(["Fuel Logs Collection"]);
        wsData.push(headers);

        let totalMiles = 0;
        let totalGallons = 0;

        // We'll track “previousMileage” so we can sum distance
        let prevMileage = null;

        sortedFuelLogs.forEach((log) => {
          const currentMiles = toNumberSafe(log.currentMileage);
          if (prevMileage !== null && currentMiles > prevMileage) {
            totalMiles += currentMiles - prevMileage;
          }
          prevMileage = currentMiles;

          const gallonsNum = toNumberSafe(log.gallons);
          totalGallons += gallonsNum;

          wsData.push([
            log.id,
            log.logDate ? log.logDate.toDate().toLocaleString() : "",
            log.engineHours ?? "",
            log.currentMileage ?? "",
            log.gallons ?? "",
            log.defGallons ?? "",
            log.userId ?? "",
          ]);
        });

        // After listing fuel logs, add a row with total average MPG
        let totalAvgMPG = "";
        if (totalGallons > 0 && totalMiles > 0) {
          totalAvgMPG = (totalMiles / totalGallons).toFixed(2);
        }
        wsData.push(["Total Average MPG:", totalAvgMPG]);
      }
      addBlankRows();

      // -----------------------------------------------------------------------------
      // (C.1) Table: Average MPG PER MONTH
      // -----------------------------------------------------------------------------
      {
        // We'll need to group the fuel logs by year-month.
        // For brevity, assume you have a helper called groupFuelLogsByMonth that returns
        // { "YYYY-MM": { totalMiles, totalGallons, totalDef, sumMiles, sumGallons, ...}}
        // But let's do a quick example inline:

        wsData.push(["Average MPG Per Month"]);
        wsData.push([
          "Month (YYYY-MM)",
          "Miles Sum",
          "Gallons Sum",
          "DEF Sum",
          "AvgMPG",
        ]);

        // We'll just pretend we computed something like:
        // e.g. { "2023-08": { miles: 5000, gallons: 400, def: 30 }, ... }
        // For brevity, we won't implement all the grouping logic here.
        // We'll assume we have a result array of objects:
        //   e.g. [ { key: "2025-01", miles: 3000, gallons: 200, def: 20 }, ... ]

        const monthlyStats = getMonthlyFuelStats(fuelLogs); // you'll define this
        monthlyStats.forEach((m) => {
          const avg = m.gallons > 0 ? (m.miles / m.gallons).toFixed(2) : "";
          wsData.push([m.key, m.miles, m.gallons, m.def, avg]);
        });
      }
      addBlankRows();

      // -----------------------------------------------------------------------------
      // (C.2) Table: Average MPG PER 3 MONTHS
      // -----------------------------------------------------------------------------
      {
        wsData.push(["Average MPG Per 3 Months"]);
        wsData.push([
          "Period (YYYY-MM to YYYY-MM)",
          "Miles",
          "Gallons",
          "DEF",
          "AvgMPG",
        ]);

        // Similar logic but group by quarter or rolling 3-month sets.
        const triMonthlyStats = getTriMonthlyFuelStats(fuelLogs);
        triMonthlyStats.forEach((tm) => {
          const avg = tm.gallons > 0 ? (tm.miles / tm.gallons).toFixed(2) : "";
          wsData.push([
            `${tm.startKey} to ${tm.endKey}`,
            tm.miles,
            tm.gallons,
            tm.def,
            avg,
          ]);
        });
      }
      addBlankRows();

      // -----------------------------------------------------------------------------
      // (C.3) Table: Fuel Logs after last filter change
      // -----------------------------------------------------------------------------
      {
        wsData.push(["Fuel Logs since Last Filter Change"]);
        wsData.push([
          "id",
          "logDate",
          "engineHours",
          "currentMileage",
          "gallons",
          "defGallons",
        ]);

        // 1) Find the newest filter log (service log)
        const newestFilterLog = filterLogs.reduce((acc, curr) => {
          if (!acc) return curr;
          if (!curr.filterChangeDate) return acc;
          if (!acc.filterChangeDate) return curr;
          return curr.filterChangeDate.toMillis() >
            acc.filterChangeDate.toMillis()
            ? curr
            : acc;
        }, null);

        if (!newestFilterLog) {
          wsData.push(["No service logs found, so no filterChangeDate."]);
        } else {
          const filterDate = safeGetDate(newestFilterLog.filterChangeDate);
          // 2) Filter fuel logs to only those after that date
          const relevantFuelLogs = fuelLogs.filter(
            (f) => f.logDate && safeGetDate(f.logDate) >= filterDate
          );

          // Sort by newest to oldest - using safe comparison
          relevantFuelLogs.sort(safeLogDateCompare);

          let totalMiles = 0,
            totalGallons = 0,
            prevMileage = null,
            totalDef = 0;

          relevantFuelLogs.forEach((log) => {
            const cMiles = toNumberSafe(log.currentMileage);
            if (prevMileage !== null && cMiles > prevMileage) {
              totalMiles += cMiles - prevMileage;
            }
            prevMileage = cMiles;
            totalGallons += toNumberSafe(log.gallons);
            totalDef += toNumberSafe(log.defGallons);

            wsData.push([
              log.id,
              log.logDate ? log.logDate.toDate().toLocaleString() : "",
              log.engineHours ?? "",
              log.currentMileage ?? "",
              log.gallons ?? "",
              log.defGallons ?? "",
            ]);
          });

          let avgMPG = "";
          if (totalGallons > 0 && totalMiles > 0) {
            avgMPG = (totalMiles / totalGallons).toFixed(2);
          }
          // Summation row:
          wsData.push([
            "Since Filter Change -> AvgMPG",
            avgMPG,
            "FuelUsed",
            totalGallons,
            "DEFUsed",
            totalDef,
            "Miles",
            totalMiles,
          ]);
        }
      }
      addBlankRows();

      // -----------------------------------------------------------------------------
      // (D) EQUIPMENT METRICS
      // -----------------------------------------------------------------------------

      wsData.push(["Equipment Metrics Collection"]);
      if (!metrics) {
        wsData.push(["No equipmentMetrics doc found"]);
      } else {
        // gather all keys
        const allKeys = Object.keys(metrics).sort();
        wsData.push(allKeys); // header
        wsData.push(allKeys.map((k) => metrics[k] ?? "")); // single row
      }

      addBlankRows();

      // -----------------------------------------------------------------------------
      // (D.1) Filter Life Calculation Steps
      // -----------------------------------------------------------------------------
      {
        // We'll replicate simplified logic from recalcFilterLifeRemaining
        wsData.push(["Filter Life Calculation Steps"]);
        wsData.push(["Step", "Value"]);

        // e.g. from recalcFilterLifeRemaining:
        // 1) get metrics.engineHours, latestServiceLog.engineHours, latestFuelLog.engineHours
        // 2) hoursUsed = latestFuelLog.engineHours - metrics.engineHours
        // 3) filterLifeHoursRemaining = metrics.filterLifeHoursMax - hoursUsed
        // And similarly for mileage.

        // for demonstration let's just do placeholders:
        const latestSrvLog = filterLogs.reduce((acc, curr) => {
          if (!acc) return curr;
          if (!curr.filterChangeDate) return acc;
          if (!acc.filterChangeDate) return curr;
          return safeGetMillis(curr.filterChangeDate) >
            safeGetMillis(acc.filterChangeDate)
            ? curr
            : acc;
        }, null);

        // We'll also find the "latest fuel log after last service log" if needed
        let relevantFuelLog = null;
        if (latestSrvLog && latestSrvLog.filterChangeDate) {
          // The date of the service log
          const filterChangeDate = safeGetDate(latestSrvLog.filterChangeDate);
          relevantFuelLog = sortedFuelLogs.find(
            (f) => f.logDate && safeGetDate(f.logDate) > filterChangeDate
          );
        }

        // assume metrics includes { engineHours, mileage, filterLifeHoursMax, ... }
        if (metrics && latestSrvLog && relevantFuelLog) {
          // Show the actual values:
          wsData.push(["metrics.engineHours", metrics.engineHours ?? ""]);
          wsData.push([
            "latestServiceLog.engineHours",
            latestSrvLog.engineHours ?? "",
          ]);
          wsData.push([
            "latestFuelLog.engineHours",
            relevantFuelLog.engineHours ?? "",
          ]);

          // hoursUsed
          const hoursUsed =
            toNumberSafe(relevantFuelLog.engineHours) -
            toNumberSafe(metrics.engineHours);
          wsData.push([
            "hoursUsed = (fuelLog - metrics.engineHours)",
            hoursUsed,
          ]);

          // filterLifeHoursRemaining
          const maxHours = toNumberSafe(metrics.filterLifeHoursMax);
          const hrsRem = maxHours - hoursUsed;
          wsData.push([
            "filterLifeHoursRemaining = (maxHours - hoursUsed)",
            hrsRem,
          ]);

          // do same for mileage if it's miles-based
          wsData.push([]);
          const milesUsed =
            toNumberSafe(relevantFuelLog.currentMileage) -
            toNumberSafe(metrics.mileage);
          wsData.push([
            "milesUsed = (fuelLog.currentMileage - metrics.mileage)",
            milesUsed,
          ]);
          const maxMiles = toNumberSafe(metrics.filterLifeMileageMax);
          const milesRem = maxMiles - milesUsed;
          wsData.push([
            "filterLifeMileageRemaining = (maxMiles - milesUsed)",
            milesRem,
          ]);
        } else {
          wsData.push([
            "Cannot compute steps: missing data (metrics, latestSrvLog, relevantFuelLog)",
          ]);
        }
      }

      // -----------------------------------------------------------------------------
      // (E) EQUIPMENT AVG MPG (eqAvgMPG)
      // -----------------------------------------------------------------------------
      {
        wsData.push([]);
        wsData.push(["EquipmentAvgMPG Collection"]);
        const allFields = new Set([
          "id",
          "year",
          "month",
          "averageMPG",
          "updatedAt",
          "equipmentId",
        ]);

        const header = Array.from(allFields);
        wsData.push(header);

        eqAvgMPG.forEach((doc) => {
          const row = header.map((field) => {
            if (field === "updatedAt" && doc.updatedAt) {
              return doc.updatedAt.toDate().toLocaleString();
            }
            return doc[field] ?? "";
          });
          wsData.push(row);
        });
      }

      // -----------------------------------------------------------------------------
      // 3) Convert wsData to a worksheet and style the header rows
      // -----------------------------------------------------------------------------
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.aoa_to_sheet(wsData);

      // 1) Decode the sheet range (the bounding rectangle of data).
      const range = XLSX.utils.decode_range(ws["!ref"] || "A1");

      // 2) We define a helper to detect a "header row."
      //    For demonstration, we say a row is "header" if it has at least 2 items and all are strings.
      //    Or you might check if row[0] === "id" or row includes certain keywords, etc.
      function isHeaderRow(row) {
        if (!row || row.length < 2) return false;
        // A simple check: ensure all items are strings or match a certain pattern.
        return row.every((item) => typeof item === "string" && item.length > 0);
        // ^ Adapt as needed. You might also check if row[0] === "id" or row[0] === "logDate", etc.
      }

      // 3) Loop through all rows in wsData.
      for (let R = range.s.r; R <= range.e.r; R++) {
        // The "raw" array for row R is wsData[R], if it exists.
        const row = wsData[R];
        if (!row) continue;

        // Decide if this is a header row
        if (isHeaderRow(row)) {
          // If so, apply style to each cell in this row
          for (let C = range.s.c; C <= range.e.c; C++) {
            // Build the cell address (e.g. "A1", "B2")
            const cellRef = XLSX.utils.encode_cell({ r: R, c: C });
            // If the sheet doesn't have a cell there, skip
            if (!ws[cellRef]) continue;
            // Apply the style object
            ws[cellRef].s = {
              font: { bold: true },
              fill: {
                patternType: "solid",
                fgColor: { rgb: "ADD8E6" }, // Light blue background
              },
            };
          }
        }
      }

      // Append the sheet and write:
      XLSX.utils.book_append_sheet(wb, ws, "ExportedData");

      // 4) Save the XLSX:
      const fileName = eqDoc.truckNumber
        ? `equipment_${eqDoc.truckNumber}.xlsx`
        : `equipment_${equipmentId}.xlsx`;

      XLSX.writeFile(wb, fileName);
    } catch (error) {
      console.error("Error exporting single equipment data:", error);
      showModalError(error.message);
    }
  };

  /**
   * getMonthlyFuelStats / getTriMonthlyFuelStats examples
   *
   * If you want more flexible grouping, you can do something similar to these
   * placeholder functions. Shown below are minimal examples that group logs
   * by Month or by "3-month block" in a simple manner.
   */

  // (Optional) group logs by Month:
  function getMonthlyFuelStats(fuelLogs) {
    // This is effectively what we did inline above in (C.1).
    // But you can factor it out if you prefer.
    const monthlyGroups = {};
    fuelLogs.forEach((log) => {
      const d = log.logDate ? log.logDate.toDate() : new Date();
      const y = d.getFullYear();
      const m = d.getMonth() + 1;
      const ymKey = `${y}-${String(m).padStart(2, "0")}`;
      if (!monthlyGroups[ymKey]) {
        monthlyGroups[ymKey] = {
          miles: 0,
          gallons: 0,
          def: 0,
          prevMileage: null,
        };
      }
      const entry = monthlyGroups[ymKey];
      const cMiles = toNumberSafe(log.currentMileage);
      if (entry.prevMileage !== null && cMiles > entry.prevMileage) {
        entry.miles += cMiles - entry.prevMileage;
      }
      entry.prevMileage = cMiles;
      entry.gallons += toNumberSafe(log.gallons);
      entry.def += toNumberSafe(log.defGallons);
    });
    // convert to array
    return Object.keys(monthlyGroups)
      .sort()
      .map((k) => {
        const mg = monthlyGroups[k];
        return {
          key: k,
          miles: mg.miles,
          gallons: mg.gallons,
          def: mg.def,
        };
      });
  }

  // (Optional) group logs by 3-month sets or quarters:
  function getTriMonthlyFuelStats(fuelLogs) {
    // Very similar approach to the monthly grouping;
    // we just figure out the quarter or rolling 3-month block
    const quarterlyGroups = {};
    fuelLogs.forEach((log) => {
      const d = log.logDate ? log.logDate.toDate() : new Date();
      const y = d.getFullYear();
      const month = d.getMonth() + 1;
      const qIndex = Math.floor((month - 1) / 3) + 1;
      const qKey = `${y}-Q${qIndex}`;

      if (!quarterlyGroups[qKey]) {
        quarterlyGroups[qKey] = {
          miles: 0,
          gallons: 0,
          def: 0,
          prevMileage: null,
        };
      }
      const entry = quarterlyGroups[qKey];
      const cMiles = toNumberSafe(log.currentMileage);
      if (entry.prevMileage !== null && cMiles > entry.prevMileage) {
        entry.miles += cMiles - entry.prevMileage;
      }
      entry.prevMileage = cMiles;
      entry.gallons += toNumberSafe(log.gallons);
      entry.def += toNumberSafe(log.defGallons);
    });

    // Convert to array
    return Object.keys(quarterlyGroups)
      .sort()
      .map((k) => {
        const qg = quarterlyGroups[k];
        return {
          startKey: k,
          endKey: k,
          miles: qg.miles,
          gallons: qg.gallons,
          def: qg.def,
        };
      });
  }

  return (
    <Container
      fluid
      className="mt-3"
      style={{ overflowX: "auto", overflowY: "auto", height: "auto" }}
    >
      <div className="d-flex flex-wrap align-items-center mb-3">
        {isAdmin && (
          <div className="me-3">
            <Form.Select
              value={selectedCompanyFilter}
              onChange={(e) => setSelectedCompanyFilter(e.target.value)}
            >
              <option value="">All Companies</option>
              {companies.map((c) => (
                <option key={c.id} value={c.id}>
                  {c.name}
                </option>
              ))}
            </Form.Select>
          </div>
        )}
        <InputGroup style={{ maxWidth: "300px" }}>
          <FormControl
            placeholder="Search by Truck # or Filter S/N"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </InputGroup>

        {/* Export Buttons */}
        <div className="ms-auto d-flex align-items-center">
          <Button variant="primary" className="me-2" onClick={handleExportXLSX}>
            Export XLSX
          </Button>
          <Button variant="primary" className="me-2" onClick={handleExportCSV}>
            Export CSV
          </Button>
          <Button variant="primary" onClick={handleExportJSON}>
            Export JSON
          </Button>
        </div>
      </div>

      <Table
        striped
        bordered
        hover
        variant="dark"
        responsive
        style={{ minWidth: "2000px" }}
      >
        <thead>
          <tr>
            <th>Truck Number</th>
            <th>Engine Make/Model</th>
            <th>Init Est MPG</th>
            <th>Fuel Filter Type</th>
            <th>Est. Next Filter Change Date</th>
            <th>Oil Filter Type</th>
            <th>Company</th>
            <th>Filter S/N</th>
            <th>Initial Mileage</th>
            <th>Initial Hours</th>
            <th style={{ color: "lightgreen" }}>Latest Fuel Log Date</th>
            <th style={{ color: "lightgreen" }}>Total Fuel Gallons</th>
            <th style={{ color: "lightgreen" }}>Total DEF Gallons</th>
            <th style={{ color: "lightgreen" }}>Avg MPG (All Time)</th>
            <th style={{ color: "lightgreen" }}>Avg MPG (Last 30 Days)</th>
            <th style={{ color: "lightblue" }}>Latest Service Log Date</th>
            <th style={{ color: "lightblue" }}>Latest Service Log Mileage</th>
            <th style={{ color: "lightblue" }}>
              Latest Service Log Engine Hours
            </th>
            <th style={{ color: "tan" }}>Max Filter Hours</th>
            <th style={{ color: "tan" }}>Remaining Filter Hours</th>
            <th style={{ color: "tan" }}>Max Filter Miles</th>
            <th style={{ color: "tan" }}>Remaining Filter Miles</th>
            <th style={{ color: "tan" }}>
              Engine Hours (At Filter Install/Change)
            </th>
            <th style={{ color: "tan" }}>Mileage (At Filter Install/Change)</th>
            {isAdmin && <th>Export</th>}
          </tr>
        </thead>
        <tbody>
          {paginatedEquipment.map((eq) => {
            const logs = fuelLogsMap[eq.id] || [];
            const {
              totalGallons,
              totalDefGallons,
              avgMPGAllTime,
              avgMPG30Days,
              latestFuelLogDate,
            } = firebaseService.calculateFuelStats(eq, logs);

            const serviceLog = serviceLogsMap[eq.id];
            const latestServiceLogMileage = serviceLog
              ? serviceLog.currentMileage.toLocaleString()
              : "";
            const latestServiceLogHours = serviceLog
              ? serviceLog.engineHours
              : "";
            const latestServiceLogDate =
              serviceLog && serviceLog.filterChangeDate
                ? formatDateForDisplay(
                    fromFirestoreTimestamp(serviceLog.filterChangeDate)
                  )
                : "";

            const companyName =
              eq.companyId && companiesMap[eq.companyId]
                ? companiesMap[eq.companyId].name
                : "Unassigned";

            const fuelFilterTypeName =
              eq.fuelFilterTypeId && filterTypeMap[eq.fuelFilterTypeId]
                ? filterTypeMap[eq.fuelFilterTypeId].name
                : "";

            const oilFilterTypeName =
              eq.oilFilterTypeId && filterTypeMap[eq.oilFilterTypeId]
                ? filterTypeMap[eq.oilFilterTypeId].name
                : "";

            // Get metrics from metricsMap
            const metrics = metricsMap[eq.id];

            const maxFilterHours = metrics?.filterLifeHoursMax ?? 0;
            const remainingFilterHours = metrics?.filterLifeHoursRemaining ?? 0;
            const maxFilterMiles = metrics?.filterLifeMileageMax ?? 0;
            const remainingFilterMiles =
              metrics?.filterLifeMileageRemaining ?? 0;
            const engineHoursAtInstall = metrics?.engineHours ?? "";
            const mileageAtInstall =
              metrics?.mileage != null
                ? formatNumberWithCommas(metrics.mileage)
                : "";

            const remainingHoursColor = getTextColorForRemaining(
              remainingFilterHours,
              maxFilterHours
            );
            const remainingMilesColor = getTextColorForRemaining(
              remainingFilterMiles,
              maxFilterMiles
            );

            return (
              <tr key={eq.id}>
                <td>{eq.truckNumber || ""}</td>
                <td>{eq.engineMakeModel || ""}</td>
                <td>{eq.estMPG || ""}</td>
                <td>{fuelFilterTypeName}</td>
                <td>
                  {eq.calcDateNextFilterChange
                    ? formatDateForDisplay(
                        fromFirestoreTimestamp(eq.calcDateNextFilterChange)
                      )
                    : ""}
                </td>
                <td>{oilFilterTypeName}</td>
                <td>{companyName}</td>
                <td>{eq.filterSerialNumber || ""}</td>
                <td>
                  {eq.initialMileage != null
                    ? formatNumberWithCommas(eq.initialMileage)
                    : ""}
                </td>
                <td>
                  {eq.initialHours != null
                    ? formatNumberWithCommas(eq.initialHours)
                    : ""}
                </td>
                <td>{latestFuelLogDate}</td>
                <td className="green-text">
                  {formatNumberWithCommas(totalGallons, 2)}
                </td>
                <td className="green-text">
                  {formatNumberWithCommas(totalDefGallons, 2)}
                </td>
                <td className="green-text">
                  {typeof avgMPGAllTime === "number"
                    ? avgMPGAllTime.toFixed(2)
                    : avgMPGAllTime}
                </td>
                <td className="green-text">
                  {typeof avgMPG30Days === "number"
                    ? avgMPG30Days.toFixed(2)
                    : avgMPG30Days}
                </td>
                <td className="blue-text">{latestServiceLogDate}</td>
                <td className="blue-text">{latestServiceLogMileage}</td>
                <td className="blue-text">{latestServiceLogHours}</td>
                <td>{formatNumberWithCommas(maxFilterHours, 2, "") || ""}</td>
                {remainingHoursColor !== "" ? (
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip>
                        {remainingHoursColor === "red"
                          ? "Replace filter"
                          : remainingHoursColor === "orange"
                          ? "Less than 10% of filter life."
                          : remainingHoursColor === "yellow"
                          ? "Less than 30% of filter life."
                          : "greater than 30%"}{" "}
                      </Tooltip>
                    }
                  >
                    <td style={{ color: remainingHoursColor }}>
                      <span>
                        {formatNumberWithCommas(remainingFilterHours, 2, "") ||
                          ""}
                      </span>
                    </td>
                  </OverlayTrigger>
                ) : (
                  <td style={{ color: remainingHoursColor }}>
                    <span>
                      {formatNumberWithCommas(remainingFilterHours, 2, "") ||
                        ""}
                    </span>
                  </td>
                )}
                <td>{formatNumberWithCommas(maxFilterMiles, 2, "") || ""}</td>
                {remainingMilesColor !== "" ? (
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      remainingMilesColor !== "" ? (
                        <Tooltip>
                          {remainingMilesColor === "red"
                            ? "Replace filter"
                            : remainingMilesColor === "orange"
                            ? "Less than 10% of filter life."
                            : remainingMilesColor === "yellow"
                            ? "Less than 30% of filter life."
                            : "greater than 30%"}{" "}
                        </Tooltip>
                      ) : null
                    }
                  >
                    <td style={{ color: remainingMilesColor }}>
                      {formatNumberWithCommas(remainingFilterMiles, 2, "") ||
                        ""}
                    </td>
                  </OverlayTrigger>
                ) : (
                  <td style={{ color: remainingMilesColor }}>
                    {formatNumberWithCommas(remainingFilterMiles, 2, "") || ""}
                  </td>
                )}
                <td>{engineHoursAtInstall}</td>
                <td>{mileageAtInstall}</td>
                {isAdmin && (
                  <td>
                    <Button
                      variant="secondary"
                      size="sm"
                      onClick={() => handleExportSingleEquipmentExcel(eq)}
                    >
                      Export
                    </Button>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </Table>

      {renderPagination()}
    </Container>
  );
}

export default EquipmentAll;
